import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { SearchListStructure } from '../../utils/searchListStructure';
import { Handle } from './modal/handle';
import { HandleRecord } from './modal/handleRecord';
import Model from './model';

const store = new Model();

const UserManagement = observer(() => {
  const { pageStore, handleStore, handleRecordStore } = store;
  return (
    <div>
      <SearchListStructure store={pageStore} />
      <Handle store={handleStore} />
      <HandleRecord store={handleRecordStore} />
    </div>
  );
});

export default UserManagement;
