import { observer } from 'mobx-react';
import type Model from './handleModel';
import { Button, Form, Input, Modal, Space } from 'antd';
import React from 'react';

interface FieldType {
  memo: string;
}

export const Handle = observer(({ store }: { store: Model }) => {
  const { visible, onCancel, onFinish, setRef, handleLoading } = store;
  return (
    <Modal
      title="处理记录"
      centered
      footer={
        <Space>
          <Button
            loading={handleLoading}
            onClick={onFinish}
            type="primary"
          >
            确定
          </Button>
        </Space>
      }
      maskClosable={false}
      onCancel={onCancel}
      open={visible}
    >
      <Form
        name="memoForm"
        autoComplete="off"
        ref={setRef}
      >
        <Form.Item<FieldType>
          label=""
          name="memo"
          rules={[
            {
              required: true,
              message: '请输入处理记录',
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            showCount
            maxLength={200}
            placeholder="请输入处理记录"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});
