import type { TableProps } from 'antd';
import { action, observable } from 'mobx';
import { request } from '../../../utils';
import { API } from '../api';
import React from 'react';
import dayjs from 'dayjs';

interface PostParams {
  id: string;
  pageNum: number;
  pageSize: number;
  status: number;
}

interface ResponseData {
  list: List[];
  pageNum: number;
  pageSize: number;
  total: number;
}

interface List {
  createBy: number;
  createByName: string;
  createTime: string;
  id: string;
  status: number;
}

export default class HandleRecordModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public visible: boolean;

  @observable public parent: any;

  @observable
  public postParams: PostParams = {
    id: '',
    pageNum: 1,
    pageSize: 10,
    status: 4,
  };

  @action
  public onShow = (row: any) => {
    if (row) {
      this.visible = true;
      this.postParams.id = row.id;
      this.getData();
    }
  };

  @action public onCancel = () => {
    this.postParams = {
      id: '',
      pageNum: 1,
      pageSize: 10,
      status: 4,
    };
    this.dataSource = [];
    this.visible = false;
  };

  /* 表格列 */
  @observable
  public columns: TableProps<List>['columns'] = [
    {
      title: '备注',
      dataIndex: 'memo',
      key: 'memo',
      width: '50%',
    },
    {
      title: '时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render: (text) => <div>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
    },
    {
      title: '操作人',
      dataIndex: 'createByName',
      key: 'createByName',
      render: (text) => <div>{`操作人：${text}`}</div>,
    },
  ];

  /* 表格数据 */
  @observable
  public dataSource: List[] = [];

  @observable
  public total = 0;

  /* 表格loading */
  @observable
  public tableLoading = false;

  /* 查询 */
  @action
  public getData = (pageNum?: number) => {
    this.tableLoading = true;
    if (pageNum) {
      this.postParams.pageNum = pageNum;
    }
    request<{ code: number; msg?: string; data: ResponseData }>({
      url: API.userDealRecord,
      method: 'POST',
      data: this.postParams,
    })
      .then((res) => {
        if (res.code === 200) {
          this.total = res.data.total;
          this.dataSource = res.data.list;
        }
      })
      .finally(() => {
        this.tableLoading = false;
      });
  };
}
