import React from 'react';
import { observer } from 'mobx-react';
import type Model from './handleRecordModel';
import { Modal, Pagination, Table } from 'antd';

export const HandleRecord = observer(({ store }: { store: Model }) => {
  const { visible, onCancel, getData, columns, dataSource, postParams, total, tableLoading } = store;
  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      onCancel={onCancel}
      open={visible}
      title="处理记录"
      width={800}
    >
      <Table
        rowKey="id"
        showHeader={false}
        dataSource={dataSource}
        columns={columns}
        loading={tableLoading}
        pagination={{
          defaultCurrent: postParams.pageNum,
          total,
          onChange: getData,
        }}
      />
    </Modal>
  );
});
