export const API = {
  /* 查询会员列表 */
  fetchMemberList: '/quan/biz_social_group_user/manage/group/user/list',
  /* 查询会员统计信息 */
  fetchMemberStatistics: '/quan/biz_social_group_user/manage/group/user/statistics',
  /* 会员处理接口 */
  userDeal: '/quan/biz_social_group_user/manage/group/user/deal',
  /* 会员处理记录 */
  userDealRecord: '/quan/biz_social_group_user/manage/group/user/logList',
};
