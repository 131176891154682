import type { FormInstance } from 'antd';
import { Form, message } from 'antd';
import { action, observable } from 'mobx';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { API } from '../api';
import _ from 'lodash';

interface PostParams {
  id: string;
  memo: string;
}

export default class HandleModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  private parent: any;

  @observable
  public visible = false;

  @observable
  public postParams: PostParams = {
    id: '',
    memo: '',
  };

  @action
  public onShow = (row: any) => {
    if (row) {
      this.postParams.id = row.id;
      this.visible = true;
    }
  };

  @action
  public onCancel = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public onHandle = () => {
    this.handleLoading = true;
    const data = this.ref.getFieldsValue();
    const postParams = {
      ...this.postParams,
      ...data,
    };
    request<{ code: number; msg?: string; data: any }>({
      url: API.userDeal,
      method: 'POST',
      data: postParams,
    })
      .then((res) => {
        if (res.code === 200) {
          this.onCancel();
          message.success('操作成功');
          this.parent?.pageStore.grid.onQuery();
        }
      })
      .finally(() => {
        this.handleLoading = false;
      });
  };

  /* 表单校验 */
  @action
  public onFinish = () => {
    this.ref.validateFields().then(() => {
      this.onHandle();
    });
  };

  @observable public ref: FormInstance;

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @observable public handleLoading = false;
}
